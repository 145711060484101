define("discourse/plugins/discourse-ratings/discourse/components/topic-rating-tip", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember-decorators/component", "jquery"], function (_exports, _component, _object, _runloop, _component2, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicRatingTip = dt7948.c(class TopicRatingTip extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      (0, _jquery.default)(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      (0, _jquery.default)(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    }
    documentClick(e) {
      let $element = (0, _jquery.default)(this.element);
      let $target = (0, _jquery.default)(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    }
    toggleDetails() {
      this.toggleProperty("showDetails");
    }
    static #_ = (() => dt7948.n(this.prototype, "toggleDetails", [_object.action]))();
  }, [(0, _component2.classNames)("topic-rating-tip")]);
  var _default = _exports.default = TopicRatingTip;
});