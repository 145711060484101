define("discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.showRatings}}
    {{#each this.model.ratings as |rating|}}
      {{select-rating rating=rating updateRating=(action "updateRating")}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "CNFZsYvk",
    "block": "[[[41,[30,0,[\"model\",\"showRatings\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"model\",\"ratings\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"rating\",\"updateRating\"],[[30,1],[28,[37,4],[[30,0],\"updateRating\"],null]]]]],[1,\"\\n\"]],[1]],null]],[]],null]],[\"rating\"],false,[\"if\",\"each\",\"-track-array\",\"select-rating\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/connectors/composer-fields/composer-controls-rating.hbs",
    "isStrictMode": false
  });
});