define("discourse/plugins/discourse-ratings/discourse/components/rating-star", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _component2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RatingStar = dt7948.c(class RatingStar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "disabled", [(0, _computed.not)("enabled")]))();
    #disabled = (() => (dt7948.i(this, "disabled"), void 0))();
    willInsertElement() {
      super.willInsertElement(...arguments);
      this.element.type = "radio";
    }
    didRender() {
      super.didRender(...arguments);
      // For IE support
      this.element.value = this.value;
    }
    click() {
      this.set("rating", this.element.value);
    }
    checked(rating) {
      return this.get("value") <= rating;
    }
    static #_2 = (() => dt7948.n(this.prototype, "checked", [(0, _decorators.default)("rating")]))();
  }, [(0, _component2.attributeBindings)("value", "checked:checked", "disabled:disabled"), (0, _component2.tagName)("input")]);
  var _default = _exports.default = RatingStar;
});