define("discourse/plugins/discourse-ratings/discourse/components/rating-object", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "discourse/models/category", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _object, _computed, _component2, _category, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RatingObject = dt7948.c(class RatingObject extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "isCategory", [(0, _computed.equal)("objectType", "category")]))();
    #isCategory = (() => (dt7948.i(this, "isCategory"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isTag", [(0, _computed.equal)("objectType", "tag")]))();
    #isTag = (() => (dt7948.i(this, "isTag"), void 0))();
    error = null;
    didReceiveAttrs() {
      super.didReceiveAttrs();
      const object = this.object;
      this.setProperties({
        currentName: object.name,
        currentTypes: object.types
      });
      if (object.name) {
        if (this.isCategory) {
          const slugPath = object.name.split("/");
          this.set("category", _category.default.findBySlugPath(slugPath));
        }
        if (this.isTag) {
          this.set("tag", object.name);
        }
      }
    }
    saveDisabled(error, objectName, objectTypes) {
      return error || !objectName || !objectTypes.length || objectName === this.currentName && JSON.stringify(objectTypes) === JSON.stringify(this.currentTypes);
    }
    static #_3 = (() => dt7948.n(this.prototype, "saveDisabled", [(0, _decorators.default)("error", "object.name", "object.types.[]")]))();
    updateCategory(categoryId) {
      const category = _category.default.findById(categoryId);
      const slug = _category.default.slugFor(category);
      const objects = this.objects || [];
      if (objects.every(o => o.name !== slug)) {
        this.setProperties({
          "object.name": slug,
          category,
          error: null
        });
      } else {
        this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
          objectType: this.objectType
        }));
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateCategory", [_object.action]))();
    updateTag(tags) {
      const objects = this.objects || [];
      const tag = tags[0];
      if (objects.every(o => o.name !== tag)) {
        this.setProperties({
          "object.name": tag,
          tag,
          error: null
        });
      } else {
        this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
          objectType: this.objectType
        }));
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateTag", [_object.action]))();
  }, [(0, _component2.tagName)("tr"), (0, _component2.classNameBindings)(":rating-object", ":admin-ratings-list-object", "error:hasError")]);
  var _default = _exports.default = RatingObject;
});