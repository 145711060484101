define("discourse/plugins/discourse-ratings/discourse/models/rating", ["exports", "@ember/object", "discourse/plugins/discourse-ratings/discourse/lib/rating-utilities"], function (_exports, _object, _ratingUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Rating extends _object.default {
    static destroy(type, data) {
      return (0, _ratingUtilities.request)("DELETE", `rating/${type}`, data);
    }
    static migrate(data) {
      return (0, _ratingUtilities.request)("POST", "rating/migrate", data);
    }
  }
  _exports.default = Rating;
});