define("discourse/plugins/discourse-ratings/discourse/components/select-rating", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _component, _object, _component2, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SelectRating = dt7948.c(class SelectRating extends _component.default {
    removeOnUncheck() {
      if (!this.rating.include) {
        this.set("rating.value", 0);
        this.updateRating(this.rating);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "removeOnUncheck", [(0, _object2.observes)("rating.include")]))();
    triggerUpdateRating() {
      this.updateRating(this.rating);
    }
    static #_2 = (() => dt7948.n(this.prototype, "triggerUpdateRating", [_object.action]))();
  }, [(0, _component2.classNames)("rating-container"), (0, _component2.tagName)("div")]);
  var _default = _exports.default = SelectRating;
});