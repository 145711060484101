define("discourse/plugins/discourse-ratings/discourse/components/rating-type", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _component2, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const minTypeLength = 2;
  const minNameLength = 2;
  const noneType = "none";
  const RatingType = dt7948.c(class RatingType extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "invalidType", [(0, _computed.lt)("type.type.length", minTypeLength)]))();
    #invalidType = (() => (dt7948.i(this, "invalidType"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "invalidName", [(0, _computed.lt)("type.name.length", minNameLength)]))();
    #invalidName = (() => (dt7948.i(this, "invalidName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "addDisabled", [(0, _computed.or)("invalidType", "invalidName")]))();
    #addDisabled = (() => (dt7948.i(this, "addDisabled"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "noneType", [(0, _computed.equal)("type.type", noneType)]))();
    #noneType = (() => (dt7948.i(this, "noneType"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showControls", [(0, _computed.not)("noneType")]))();
    #showControls = (() => (dt7948.i(this, "showControls"), void 0))();
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.set("currentName", this.type.name);
    }
    updateDisabled(invalidName, name, currentName) {
      return invalidName || name === currentName;
    }
    static #_6 = (() => dt7948.n(this.prototype, "updateDisabled", [(0, _decorators.default)("invalidName", "type.name", "currentName")]))();
  }, [(0, _component2.classNameBindings)(":rating-type", ":admin-ratings-list-object", "hasError"), (0, _component2.tagName)("tr")]);
  var _default = _exports.default = RatingType;
});