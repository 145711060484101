define("discourse/plugins/discourse-ratings/discourse/models/rating-type", ["exports", "@ember/object", "discourse/plugins/discourse-ratings/discourse/lib/rating-utilities"], function (_exports, _object, _ratingUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class RatingType extends _object.default {
    static all() {
      return (0, _ratingUtilities.request)("GET", "rating-type");
    }
    static add(data) {
      return (0, _ratingUtilities.request)("POST", "rating-type", data);
    }
    static update(type, data) {
      return (0, _ratingUtilities.request)("PUT", `rating-type/${type}`, data);
    }
    static destroy(type) {
      return (0, _ratingUtilities.request)("DELETE", `rating-type/${type}`);
    }
  }
  _exports.default = RatingType;
});