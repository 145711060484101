define("discourse/plugins/discourse-ratings/discourse/templates/components/star-rating", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.stars as |star|}}
    {{rating-star value=star rating=this.rating enabled=this.enabled}}<i></i>
  {{/each}}
  */
  {
    "id": "K/74/Cyj",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"stars\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"value\",\"rating\",\"enabled\"],[[30,1],[30,0,[\"rating\"]],[30,0,[\"enabled\"]]]]]],[10,\"i\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"star\"],false,[\"each\",\"-track-array\",\"rating-star\",\"i\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/star-rating.hbs",
    "isStrictMode": false
  });
});