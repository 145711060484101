define("discourse/plugins/discourse-ratings/discourse/components/rating-migrate", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating"], function (_exports, _component, _object, _component2, _decorators, _rating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noneType = "none";
  const RatingMigrate = dt7948.c(class RatingMigrate extends _component.default {
    migrateDisabled(categoryId, toType, fromType) {
      return [categoryId, toType, fromType].any(i => !i) || toType !== noneType && fromType !== noneType;
    }
    static #_ = (() => dt7948.n(this.prototype, "migrateDisabled", [(0, _decorators.default)("categoryId", "toType", "fromType")]))();
    migrate() {
      let data = {
        category_id: this.categoryId,
        type: this.fromType,
        new_type: this.toType
      };
      this.set("startingMigration", true);
      _rating.default.migrate(data).then(result => {
        if (result.success) {
          this.set("migrationMessage", "admin.ratings.migrate.started");
        } else {
          this.set("migrationMessage", "admin.ratings.error.migration_failed_to_start");
        }
      }).finally(() => this.set("startingMigration", false));
    }
    static #_2 = (() => dt7948.n(this.prototype, "migrate", [_object.action]))();
  }, [(0, _component2.classNames)("rating-action-controls")]);
  var _default = _exports.default = RatingMigrate;
});