define("discourse/plugins/discourse-ratings/discourse/components/rating-object-list", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-ratings/discourse/models/rating-object"], function (_exports, _component, _object, _computed, _component2, _decorators, _I18n, _ratingObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RatingObjectList = dt7948.c(class RatingObjectList extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "hasObjects", [(0, _computed.notEmpty)("objects")]))();
    #hasObjects = (() => (dt7948.i(this, "hasObjects"), void 0))();
    title(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.title`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "title", [(0, _decorators.default)("objectType")]))();
    nameLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.name`);
    }
    static #_3 = (() => dt7948.n(this.prototype, "nameLabel", [(0, _decorators.default)("objectType")]))();
    noneLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.none`);
    }
    static #_4 = (() => dt7948.n(this.prototype, "noneLabel", [(0, _decorators.default)("objectType")]))();
    newObject() {
      this.get("objects").pushObject({
        name: "",
        types: [],
        isNew: true
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "newObject", [_object.action]))();
    addObject(obj) {
      let data = {
        name: obj.name,
        types: obj.types,
        type: this.objectType
      };
      this.set("loading", true);
      _ratingObject.default.add(data).then(result => {
        if (result.success) {
          this.refresh();
        } else {
          obj.set("hasError", true);
        }
        this.set("loading", false);
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "addObject", [_object.action]))();
    updateObject(obj) {
      let data = {
        name: obj.name,
        types: obj.types
      };
      this.set("loading", true);
      _ratingObject.default.update(this.objectType, data).then(result => {
        if (result.success) {
          this.refresh();
        } else {
          obj.set("hasError", true);
        }
        this.set("loading", false);
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateObject", [_object.action]))();
    destroyObject(obj) {
      if (obj.isNew) {
        this.get("objects").removeObject(obj);
      } else {
        let data = {
          name: obj.name
        };
        this.set("loading", true);
        _ratingObject.default.destroy(this.objectType, data).then(result => {
          if (result.success) {
            this.refresh();
          } else {
            obj.set("hasError", true);
          }
          this.set("loading", false);
        });
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "destroyObject", [_object.action]))();
  }, [(0, _component2.classNameBindings)(":object-types", ":admin-ratings-list", "objectType")]);
  var _default = _exports.default = RatingObjectList;
});