define("discourse/plugins/discourse-ratings/discourse/components/star-rating", ["exports", "@ember/component", "@ember-decorators/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StarRating = dt7948.c(class StarRating extends _component.default {
    stars = [1, 2, 3, 4, 5];
    enabled = false;
  }, [(0, _component2.classNames)("star-rating"), (0, _component2.tagName)("span")]);
  var _default = _exports.default = StarRating;
});