define("discourse/plugins/discourse-ratings/discourse/templates/admin/plugins-ratings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="types admin-ratings-list">
    <h3>{{i18n "admin.ratings.type.title"}}</h3>
  
    {{#if this.hasTypes}}
      <table>
        <thead>
          <tr>
            <th>{{i18n "admin.ratings.type.label"}}</th>
            <th>{{i18n "admin.ratings.type.name"}}</th>
          </tr>
        </thead>
        <tbody>
          {{#each this.ratingTypes as |type|}}
            {{rating-type
              addType=(action "addType")
              updateType=(action "updateType")
              destroyType=(action "destroyType")
              type=type
            }}
          {{/each}}
        </tbody>
      </table>
    {{else}}
      {{i18n "admin.ratings.type.none"}}
    {{/if}}
  
    <div class="admin-ratings-list-controls">
      {{d-button
        action=(action "newType")
        label="admin.ratings.type.new"
        icon="plus"
      }}
    </div>
  </div>
  
  {{rating-object-list
    objectType="category"
    objects=this.categoryTypes
    ratingTypes=this.ratingTypes
    refresh=(route-action "refresh")
  }}
  
  {{rating-object-list
    objectType="tag"
    objects=this.tagTypes
    ratingTypes=this.ratingTypes
    refresh=(route-action "refresh")
  }}
  
  {{rating-actions ratingTypes=this.ratingTypes}}
  */
  {
    "id": "X4liGnJ2",
    "block": "[[[10,0],[14,0,\"types admin-ratings-list\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"admin.ratings.type.title\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasTypes\"]],[[[1,\"    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,2],[\"admin.ratings.type.label\"],null]],[13],[1,\"\\n          \"],[10,\"th\"],[12],[1,[28,[35,2],[\"admin.ratings.type.name\"],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,10],[[28,[37,10],[[30,0,[\"ratingTypes\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,11],null,[[\"addType\",\"updateType\",\"destroyType\",\"type\"],[[28,[37,12],[[30,0],\"addType\"],null],[28,[37,12],[[30,0],\"updateType\"],null],[28,[37,12],[[30,0],\"destroyType\"],null],[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[\"admin.ratings.type.none\"],null]],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[10,0],[14,0,\"admin-ratings-list-controls\"],[12],[1,\"\\n    \"],[1,[28,[35,13],null,[[\"action\",\"label\",\"icon\"],[[28,[37,12],[[30,0],\"newType\"],null],\"admin.ratings.type.new\",\"plus\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[1,[28,[35,14],null,[[\"objectType\",\"objects\",\"ratingTypes\",\"refresh\"],[\"category\",[30,0,[\"categoryTypes\"]],[30,0,[\"ratingTypes\"]],[28,[37,15],[\"refresh\"],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,14],null,[[\"objectType\",\"objects\",\"ratingTypes\",\"refresh\"],[\"tag\",[30,0,[\"tagTypes\"]],[30,0,[\"ratingTypes\"]],[28,[37,15],[\"refresh\"],null]]]]],[1,\"\\n\\n\"],[1,[28,[35,16],null,[[\"ratingTypes\"],[[30,0,[\"ratingTypes\"]]]]]]],[\"type\"],false,[\"div\",\"h3\",\"i18n\",\"if\",\"table\",\"thead\",\"tr\",\"th\",\"tbody\",\"each\",\"-track-array\",\"rating-type\",\"action\",\"d-button\",\"rating-object-list\",\"route-action\",\"rating-actions\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/admin/plugins-ratings.hbs",
    "isStrictMode": false
  });
});