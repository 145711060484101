define("discourse/plugins/discourse-ratings/discourse/templates/connectors/topic-title/topic-rating-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.show_ratings}}
    {{rating-list this.model.ratings topic=this.model}}
  {{/if}}
  */
  {
    "id": "YkDOlWP8",
    "block": "[[[41,[30,0,[\"model\",\"show_ratings\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"model\",\"ratings\"]]],[[\"topic\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"rating-list\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/connectors/topic-title/topic-rating-container.hbs",
    "isStrictMode": false
  });
});