define("discourse/plugins/discourse-ratings/discourse/components/rating-type-chooser", ["exports", "@ember-decorators/component", "select-kit/components/multi-select", "select-kit/components/select-kit"], function (_exports, _component, _multiSelect, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RatingTypeChooser = dt7948.c(class RatingTypeChooser extends _multiSelect.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.types) {
        this.set("value", this.types.split("|"));
      }
    }
    onChange(value) {
      this.set("types", value.join("|"));
    }
  }, [(0, _component.classNames)("rating-type-chooser"), (0, _selectKit.selectKitOptions)({
    filterable: true
  })]);
  var _default = _exports.default = RatingTypeChooser;
});