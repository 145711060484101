define("discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a role="button" {{on "click" (action "toggleDetails")}}>
    {{d-icon "circle-info"}}
  </a>
  {{#if this.showDetails}}
    <div class="tip-details">
      {{html-safe (i18n this.details)}}
    </div>
  {{/if}}
  */
  {
    "id": "5tGIg6wl",
    "block": "[[[11,3],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,0],\"toggleDetails\"],null]],null],[12],[1,\"\\n  \"],[1,[28,[35,3],[\"circle-info\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showDetails\"]],[[[1,\"  \"],[10,0],[14,0,\"tip-details\"],[12],[1,\"\\n    \"],[1,[28,[35,6],[[28,[37,7],[[30,0,[\"details\"]]],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"a\",\"on\",\"action\",\"d-icon\",\"if\",\"div\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/topic-rating-tip.hbs",
    "isStrictMode": false
  });
});