define("discourse/plugins/discourse-ratings/discourse/templates/components/rating-type", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <td>
    {{#if this.type.isNew}}
      {{input
        value=this.type.type
        placeholder=(i18n "admin.ratings.type.type_placeholder")
      }}
    {{else}}
      {{this.type.type}}
    {{/if}}
  </td>
  
  <td>
    {{#if this.type.isNone}}
      {{i18n "admin.ratings.type.none_type_description"}}
    {{else}}
      {{input
        value=this.type.name
        placeholder=(i18n "admin.ratings.type.name_placeholder")
      }}
    {{/if}}
  </td>
  
  <td class="type-controls">
    {{#if this.showControls}}
      {{#if this.type.isNew}}
        {{d-button
          class="btn-primary"
          action=this.addType
          actionParam=this.type
          label="admin.ratings.type.add"
          icon="plus"
          disabled=this.addDisabled
        }}
      {{else}}
        {{d-button
          class="btn-primary"
          action=this.updateType
          actionParam=this.type
          label="admin.ratings.type.update"
          icon="save"
          disabled=this.updateDisabled
        }}
      {{/if}}
  
      {{d-button action=this.destroyType actionParam=this.type icon="times"}}
    {{/if}}
  </td>
  */
  {
    "id": "6nMlQ2XW",
    "block": "[[[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"type\",\"isNew\"]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"value\",\"placeholder\"],[[30,0,[\"type\",\"type\"]],[28,[37,3],[\"admin.ratings.type.type_placeholder\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"type\",\"type\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"type\",\"isNone\"]],[[[1,\"    \"],[1,[28,[35,3],[\"admin.ratings.type.none_type_description\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],null,[[\"value\",\"placeholder\"],[[30,0,[\"type\",\"name\"]],[28,[37,3],[\"admin.ratings.type.name_placeholder\"],null]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"type-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"showControls\"]],[[[41,[30,0,[\"type\",\"isNew\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"class\",\"action\",\"actionParam\",\"label\",\"icon\",\"disabled\"],[\"btn-primary\",[30,0,[\"addType\"]],[30,0,[\"type\"]],\"admin.ratings.type.add\",\"plus\",[30,0,[\"addDisabled\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"class\",\"action\",\"actionParam\",\"label\",\"icon\",\"disabled\"],[\"btn-primary\",[30,0,[\"updateType\"]],[30,0,[\"type\"]],\"admin.ratings.type.update\",\"save\",[30,0,[\"updateDisabled\"]]]]]],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"action\",\"actionParam\",\"icon\"],[[30,0,[\"destroyType\"]],[30,0,[\"type\"]],\"times\"]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"td\",\"if\",\"input\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-ratings/discourse/templates/components/rating-type.hbs",
    "isStrictMode": false
  });
});